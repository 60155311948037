import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import OpenEarLogo from '../OpenEarLogo';
import './styles.scss';

class OpenEarLoadingScreen extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: true
        };
    }

    componentDidMount() {
        this.setTimer();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    setTimer() {
        if (this.timer != null) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.setState({
                visible: false
            });
            this.timer = null;
        }, 1000);
    }

    render() {
        const {
            visible
        } = this.state;

        return (
            <CSSTransition
                classNames="open-ear-loading-screen"
                in={visible}
                timeout={1000}
                unmountOnExit
            >
                <div className="open-ear-loading-screen">
                    <div className="open-ear-loading-screen__content">
                        <p>Powered by</p>
                        <OpenEarLogo />
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default OpenEarLoadingScreen;
