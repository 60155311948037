import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import './styles.scss';

class Overlay extends PureComponent {
    constructor(props) {
        super(props);
        this.target = React.createRef();
    }

    componentDidUpdate() {
        const {
            showOverlay
        } = this.props;

        if (showOverlay) {
            disableBodyScroll(this.target.current);
        } else {
            enableBodyScroll(this.target.current);
        }
    }

    render() {
        const {
            children,
            closeFunc,
            showOverlay,
            style
        } = this.props;

        return (
            <CSSTransition
                classNames="overlay"
                in={showOverlay}
                timeout={300}
                unmountOnExit
            >
                <div
                    className="overlay"
                    ref={this.target}
                    style={style}
                >
                    <button
                        type="button"
                        className="overlay__close"
                        onClick={closeFunc}
                    >
                        <span
                            className="overlay__cross overlay__cross--right"
                        />
                        <span
                            className="overlay__cross overlay__cross--left"
                        />
                    </button>
                    {children}
                </div>
            </CSSTransition>
        );
    }
}

Overlay.defaultProps = {
    showOverlay: false
};

Overlay.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    closeFunc: PropTypes.func.isRequired,
    showOverlay: PropTypes.bool
};

export default Overlay;
