import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormInput from '../FormInput';
import Button from '../Button';
import SpotifyLink from '../SpotifyLink';
import validate from './validation';
import './styles.scss';

// Actions
import {
    submitEmailForm
} from '../../actions/email-form';

class EmailForm extends PureComponent {
    render() {
        const {
            formCtaBgColour,
            formCtaText,
            formCtaTextColour,
            formCtaTextBold,
            formBgColour,
            formHeaderTextColour,
            formTextColour,
            emailFormSuccess,
            formText,
            formHeaderText,
            errors,
            goSubmitform,
            handleSubmit,
            isSubmitting,
            policyLink,
            spotifyLink,
            venueName
        } = this.props;

        return (
            <div
                className="email-form"
                style={{ backgroundColor: formBgColour }}
            >
                <h3 style={{ color: formHeaderTextColour }}>{formHeaderText || 'Get the playlist'}</h3>

                {emailFormSuccess ? (
                    <Fragment>
                        <p>Great! An email has been sent to your inbox.</p>
                        {spotifyLink && (
                            <SpotifyLink
                                venueName={venueName}
                                {...spotifyLink}
                            />
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {Object.keys(errors).length > 0 ? (
                            <p style={{ color: formTextColour }}>There has been an error submitting the form. Please check your details and try again</p>
                        ) : (
                            <p style={{ color: formTextColour }}>{formText || 'Sign up for our newsletter to access the playlist'}</p>
                        )}

                        <form onSubmit={handleSubmit(goSubmitform)}>
                            <FormInput
                                label="Full name"
                                name="name"
                                type="text"
                            />
                            <FormInput
                                label="Email"
                                name="email"
                                type="text"
                            />
                            {policyLink && (
                                <p>
                                    <a
                                        className="email-form__policy-link"
                                        href={policyLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                </p>
                            )}
                            <Button
                                text={formCtaText || 'Send'}
                                type="submit"
                                isSubmitting={isSubmitting}
                                style={{
                                    color: formCtaTextColour,
                                    backgroundColor: formCtaBgColour,
                                    fontWeight: formCtaTextBold ? 'bold' : 'normal'
                                }}
                            />
                        </form>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSubmitting: state.emailFormState.isSubmitting,
        emailFormSuccess: state.emailFormState.emailFormSuccess,
        errors: state.emailFormState.errors
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        goSubmitform: (values) => {
            dispatch(
                submitEmailForm(
                    values,
                    props.mailchimpEndpoint,
                    props.venueName
                )
            );
        }
    };
};

EmailForm.defaultProps = {
    formText: 'Sign up for our newsletter to access the playlist',
    formHeaderText: 'Get the playlist',
    formHeaderTextColour: 'rgba(197,80,43,1)',
    formTextColour: '#000',
    formBgColour: 'transparent',
    formCtaBgColour: '#5b8bf7',
    formCtaText: 'Send',
    formCtaTextColour: '#fff',
    formCtaTextBold: false,
    emailFormSuccess: false,
    errors: [],
    isSubmitting: false,
    policyLink: '',
    spotifyLink: {},
    venueName: 'Open Ear'
};

EmailForm.propTypes = {
    formText: PropTypes.string,
    formHeaderText: PropTypes.string,
    formHeaderTextColour: PropTypes.string,
    formTextColour: PropTypes.string,
    formBgColour: PropTypes.string,
    formCtaBgColour: PropTypes.string,
    formCtaText: PropTypes.string,
    formCtaTextColour: PropTypes.string,
    formCtaTextBold: PropTypes.bool,
    emailFormSuccess: PropTypes.bool,
    errors: PropTypes.array,
    goSubmitform: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    policyLink: PropTypes.string,
    spotifyLink: PropTypes.object,
    venueName: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)((reduxForm({
    enableReinitialize: true,
    validate
})(EmailForm)));
