import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Overlay from '../Overlay';
import EmailForm from '../EmailForm';
import Button from '../Button';
import OutboundLink from '../../helpers/outboundLink';
import './styles.scss';

class PromoBlock extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showOverlay: false
        };

        this.toggleOverlay = this.toggleOverlay.bind(this);
        this.openOverlay = this.openOverlay.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
    }

    toggleOverlay() {
        const {
            showOverlay
        } = this.state;

        this.setState({
            showOverlay: !showOverlay
        });
    }

    openOverlay() {
        const {
            venueName
        } = this.props;

        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag(
                'event',
                'Open overlay',
                {
                    'event_category': 'Promo Block',
                    'event_label': venueName
                }
            );
        }

        this.toggleOverlay();
    }

    closeOverlay() {
        const {
            venueName
        } = this.props;

        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag(
                'event',
                'Close overlay',
                {
                    'event_category': 'Promo Block',
                    'event_label': venueName
                }
            );
        }

        this.toggleOverlay();
    }

    render() {
        const {
            showOverlay
        } = this.state;

        const {
            addPaddingToPromoTextImages,
            promoBlockBgColour,
            promoBlockBgImage,
            promoBlockTextColour,
            promoCta,
            promoImage,
            promoText,
            venueName
        } = this.props;

        const options = {
            renderMark: {
                [MARKS.BOLD]: text => <strong>{text}</strong>
            },
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: node => <img src={`${node.data.target.fields.file['en-US'].url}`} alt={`${node.data.target.fields.title['en-US']}`} />
            }
        };

        const promoCtaFontWeight = promoCta && promoCta.ctaFontWeight ? 'bold' : 'normal';

        return (
            <Fragment>
                <div
                    className="promo-block"
                    style={{
                        backgroundImage: promoBlockBgImage ? `url(${promoBlockBgImage.file.url})` : 'none',
                        backgroundColor: promoBlockBgColour || '#fff',
                        color: promoBlockTextColour || '#000'
                    }}
                >
                    {promoImage && (
                        <img
                            src={promoImage.file.url}
                            alt={promoImage.title}
                        />
                    )}
                    {promoText && (
                        <div
                            className={`promo-block__copy${addPaddingToPromoTextImages ? ' promo-block__copy--image-padding' : '' }`}
                        >
                            {documentToReactComponents(promoText.json, options)}
                        </div>
                    )}
                    {promoCta && (
                        <div className="promo-block__cta-wrapper">
                            {promoCta.ctaType === 'form' ? (
                                <Button
                                    handleClick={() => { this.openOverlay(); }}
                                    className="connect-hero__cta"
                                    type="button"
                                    text={promoCta.ctaText || `Listen to ${venueName}`}
                                    style={{
                                        backgroundColor: promoCta.ctaBgColour,
                                        color: promoCta.ctaColour,
                                        fontWeight: promoCtaFontWeight
                                    }}
                                />
                            ) : (
                                <OutboundLink
                                    className="button"
                                    eventLabel={`Hero - Spotify Link - ${venueName}`}
                                    style={{
                                        backgroundColor: promoCta.ctaBgColour,
                                        color: promoCta.ctaColour,
                                        fontWeight: promoCtaFontWeight
                                    }}
                                    href={`https://open.spotify.com/${promoCta.ctaType === 'spotify-playlist' ? 'playlist' : 'user'}/${promoCta.spotifyToken}`}
                                    target="_blank"
                                >
                                    {promoCta.ctaText || `Listen to ${venueName}`}
                                </OutboundLink>
                            )}
                        </div>
                    )}
                </div>
                {promoCta && promoCta.emailForm && (
                    <Overlay
                        closeFunc={() => { this.closeOverlay(); }}
                        showOverlay={showOverlay}
                        style={{ backgroundColor: promoCta && promoCta.emailForm ? promoCta.emailForm.formBgColour : '' }}
                    >
                        <EmailForm
                            form="promo-block__email-form"
                            venueName={venueName}
                            {...promoCta.emailForm}
                        />
                    </Overlay>
                )}
            </Fragment>
        );
    }
}

PromoBlock.defaultProps = {
    addPaddingToPromoTextImages: false,
    promoBlockBgColour: null,
    promoBlockBgImage: {},
    promoBlockTextColour: null,
    promoCta: {},
    promoImage: {},
    promoText: {},
    venueName: 'Open Ear'
};

PromoBlock.propTypes = {
    addPaddingToPromoTextImages: PropTypes.bool,
    promoBlockBgColour: PropTypes.string,
    promoBlockBgImage: PropTypes.object,
    promoBlockTextColour: PropTypes.string,
    promoCta: PropTypes.object,
    promoImage: PropTypes.object,
    promoText: PropTypes.object,
    venueName: PropTypes.string
};

export default PromoBlock;
