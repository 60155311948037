import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Overlay from '../Overlay';
import EmailForm from '../EmailForm';
import Button from '../Button';
import OutboundLink from '../../helpers/outboundLink';

import './styles.scss';

class ConnectHero extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showOverlay: false
        };

        this.toggleOverlay = this.toggleOverlay.bind(this);
        this.openOverlay = this.openOverlay.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
    }

    toggleOverlay() {
        const {
            showOverlay
        } = this.state;

        this.setState({
            showOverlay: !showOverlay
        });
    }

    openOverlay() {
        const {
            venueName
        } = this.props;

        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag(
                'event',
                'Open overlay',
                {
                    'event_category': 'Hero',
                    'event_label': venueName
                }
            );
        }

        this.toggleOverlay();
    }

    closeOverlay() {
        const {
            venueName
        } = this.props;

        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag(
                'event',
                'Close overlay',
                {
                    'event_category': 'Hero',
                    'event_label': venueName
                }
            );
        }

        this.toggleOverlay();
    }

    render() {
        const {
            showOverlay
        } = this.state;

        const {
            heroCta,
            heroImage,
            heroText,
            heroTextColour,
            heroTextShadow,
            venueName
        } = this.props;

        const heroCtaFontWeight = heroCta && heroCta.ctaFontWeight ? 'bold' : 'normal';

        return (
            <Fragment>
                <div
                    className="connect-hero"
                    style={{ backgroundImage: `url(${heroImage.file.url}` }}
                >
                    <h1
                        className="connect-hero__heading"
                        style={{
                            color: heroTextColour || '#fff',
                            textShadow: `1px 1px ${heroTextShadow}` || 'none'
                        }}
                    >
                        {heroText}
                    </h1>
                    {heroCta && (
                        <Fragment>
                            {heroCta.ctaType === 'form'? (
                                <Button
                                    handleClick={() => { this.openOverlay(); }}
                                    className="connect-hero__cta"
                                    type="button"
                                    text={heroCta.ctaText || `Listen to ${venueName}`}
                                    style={{
                                        backgroundColor: heroCta.ctaBgColour,
                                        color: heroCta.ctaColour,
                                        fontWeight: heroCtaFontWeight
                                    }}
                                />
                            ) : (
                                <OutboundLink
                                    className="button"
                                    eventLabel={`Hero - Spotify Link - ${venueName}`}
                                    style={{
                                        backgroundColor: heroCta.ctaBgColour,
                                        color: heroCta.ctaColour,
                                        fontWeight: heroCtaFontWeight
                                    }}
                                    href={`https://open.spotify.com/${heroCta.ctaType === 'spotify-playlist' ? 'playlist' : 'user'}/${heroCta.spotifyToken}`}
                                    target="_blank"
                                >
                                    {heroCta.ctaText || `Listen to ${venueName}`}
                                </OutboundLink>
                            )}
                        </Fragment>
                    )}
                </div>
                {heroCta && heroCta.emailForm && (
                    <Overlay
                        closeFunc={() => { this.closeOverlay(); }}
                        showOverlay={showOverlay}
                        style={{ backgroundColor: heroCta && heroCta.emailForm ? heroCta.emailForm.formBgColour : '' }}
                    >
                        <EmailForm
                            form="connect-hero__email-form"
                            venueName={venueName}
                            {...heroCta.emailForm}
                        />
                    </Overlay>
                )}
            </Fragment>
        );
    }
}

ConnectHero.defaultProps = {
    heroCta: {},
    heroImage: {},
    heroText: null,
    heroTextColour: null,
    heroTextShadow: null,
    venueName: 'Open Ear'
};

ConnectHero.propTypes = {
    heroCta: PropTypes.object,
    heroImage: PropTypes.object,
    heroText: PropTypes.string,
    heroTextColour: PropTypes.string,
    heroTextShadow: PropTypes.string,
    venueName: PropTypes.string
};

export default ConnectHero;
