import axios from 'axios';
import * as types from '../constants/actiontypes';
import { SIGNUP_FORM } from '../constants/endpoints';

export const submittingSignUpForm = () => {
    return {
        type: types.SUBMITTING_SIGNUP_FORM
    };
};

export const signUpFormSuccess = (message) => {
    return {
        type: types.SIGNUP_FORM_SUCCESS,
        message
    };
};

export const signUpFormError = (errors) => {
    return {
        type: types.SIGNUP_FORM_ERROR,
        errors
    };
};

export const submitSignUpForm = (values) => {
    return (dispatch) => {
        const endpoint = SIGNUP_FORM;
        dispatch(submittingSignUpForm());

        return axios.post(endpoint, values)
            .then((response) => {
                if (response.data.error) {
                    if (typeof window !== 'undefined' && window.gtag) {
                        window.gtag(
                            'event',
                            'Error',
                            {
                                'event_category': 'Sign Up Form',
                                'event_label': 'Open Ear Office'
                            }
                        );
                    }

                    return dispatch(signUpFormError(response.data.error));
                } else {
                    if (typeof window !== 'undefined' && window.gtag) {
                        window.gtag(
                            'event',
                            'Success',
                            {
                                'event_category': 'Sign Up Form',
                                'event_label': 'Open Ear Office'
                            }
                        );
                    }
                    return dispatch(signUpFormSuccess(response.data.message));
                }
            })
            .catch((error) => {
                console.log(error);

                if (typeof window !== 'undefined' && window.gtag) {
                    window.gtag(
                        'event',
                        'Error',
                        {
                            'event_category': 'Sign Up Form',
                            'event_label': 'Open Ear Office'
                        }
                    );
                }

                return dispatch(signUpFormError(error));
            });
    };
};
