import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class ConnectHeader extends PureComponent {
    render() {
        const {
            headerImage
        } = this.props;

        return (
            <div className="connect-header">
                <img
                    src={headerImage.file.url}
                    alt={headerImage.title}
                />
            </div>
        );
    }
}

ConnectHeader.propTypes = {
    headerImage: PropTypes.object.isRequired
};

export default ConnectHeader;
