import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class SpotifyPlayer extends PureComponent {
    render() {
        const {
            spotifyToken
        } = this.props;

        return (
            <iframe
                className="spotify-player"
                src={`https://open.spotify.com/embed/playlist/${spotifyToken}`}
                frameBorder="0"
                allow="encrypted-media"
                title="Spotify"
            />
        );
    }
}

SpotifyPlayer.defaultProps = {
    spotifyToken: '0bOpROzZCE7tF1O6BD4TE7'
};

SpotifyPlayer.propTypes = {
    spotifyToken: PropTypes.string
};

export default SpotifyPlayer;
