import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SoundcloudEmbed extends PureComponent {
    render() {
        const {
            embedCode
        } = this.props;

        return (
            <div dangerouslySetInnerHTML={{ __html: embedCode.embedCode }} />
        );
    }
}

SoundcloudEmbed.defaultProps = {
    embedCode: '0bOpROzZCE7tF1O6BD4TE7'
};

SoundcloudEmbed.propTypes = {
    embedCode: PropTypes.string
};

export default SoundcloudEmbed;
