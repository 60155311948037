// import { trans } from '../../../common/helpers/translationsWrapper.js';
import * as EmailValidator from 'email-validator';

const requiredFields = [
    'company_name',
    'email',
    'password',
    'phone_number'
];

const validate = (values) => {
    const errors = [];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required field';
        }
    });

    if (values.password && values.password.length < 5) {
        errors.password = 'Please enter at least 5 characters';
    }

    if (!values.email) {
        errors.email = 'Required field';
    } else if (!EmailValidator.validate(values.email)) {
        errors.email = 'Email is not in a valid format';
    }

    return errors;
};

export default validate;
