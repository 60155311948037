import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormInput from '../FormInput';
import Button from '../Button';
import validate from './validation';
import transform from '../../serializers/formDataTransformer';
import urlEncodeFormData from '../../serializers/urlEncodeFormData';
import './styles.scss';

// Actions
import {
    submitSignUpForm
} from '../../actions/sign-up-form';

class SignUpForm extends PureComponent {
    render() {
        const {
            signUpFormSuccess,
            signUpFormSuccessMessage,
            errors,
            goSubmitSignUpForm,
            handleSubmit,
            isSubmitting
        } = this.props;

        return (
            <div className="sign-up-form">
                <h3>Sign Up</h3>

                {signUpFormSuccess ? (
                    <Fragment>
                        <p>{signUpFormSuccessMessage}</p>
                    </Fragment>
                ) : (
                    <Fragment>
                        {Object.keys(errors).length > 0 ? (
                            <p className="sign-up-form__error">{errors}</p>
                        ) : (
                            <p className="sign-up-form__promo-text">Get your first month free</p>
                        )}

                        <form onSubmit={handleSubmit(goSubmitSignUpForm)}>
                            <FormInput
                                label="Company name"
                                name="company_name"
                                type="text"
                            />
                            <FormInput
                                label="Email"
                                name="email"
                                type="text"
                            />
                            <FormInput
                                label="Password"
                                name="password"
                                type="password"
                            />
                            <FormInput
                                label="Phone number"
                                name="phone_number"
                                type="phone"
                            />
                            <p className="terms">
                                By clicking try for free, you indicate that you have read and understand our <a href="https://openearmusic.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://openearmusic.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
                            </p>
                            <Button
                                text="TRY FOR FREE"
                                type="submit"
                                isSubmitting={isSubmitting}
                                style={{ fontWeight: 'bold' }}
                            />
                        </form>
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSubmitting: state.signUpFormState.isSubmitting,
        signUpFormSuccess: state.signUpFormState.signUpFormSuccess,
        signUpFormSuccessMessage: state.signUpFormState.signUpFormSuccessMessage,
        errors: state.signUpFormState.errors
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goSubmitSignUpForm: (values) => {
            dispatch(
                submitSignUpForm(
                    urlEncodeFormData(
                        transform(values)
                    )
                )
            );
        }
    };
};

// EmailForm.defaultProps = {
//     emailCtaBgColour: '#5b8bf7',
//     emailCtaTextColour: '#fff',
//     emailCtaTextBold: false,
//     emailFormSuccess: false,
//     errors: [],
//     isSubmitting: false,
//     policyLink: '',
//     spotifyToken: '',
//     venueName: 'Open Ear'
// };
//
// EmailForm.propTypes = {
//     emailCtaBgColour: PropTypes.string,
//     emailCtaTextColour: PropTypes.string,
//     emailCtaTextBold: PropTypes.bool,
//     emailFormSuccess: PropTypes.bool,
//     errors: PropTypes.array,
//     goSubmitEmailForm: PropTypes.func.isRequired,
//     handleSubmit: PropTypes.func.isRequired,
//     isSubmitting: PropTypes.bool,
//     policyLink: PropTypes.string,
//     spotifyToken: PropTypes.string,
//     venueName: PropTypes.string
// };

export default connect(mapStateToProps, mapDispatchToProps)((reduxForm({
    enableReinitialize: true,
    validate
})(SignUpForm)));
