import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FaSpotify } from 'react-icons/fa';
import OutboundLink from '../../helpers/outboundLink';
import './styles.scss';

class SpotifyLink extends PureComponent {
    render() {
        const {
            linkText,
            linkType,
            linkColour,
            linkBgColour,
            spotifyToken,
            venueName
        } = this.props;

        const spotifyLink = `https://open.spotify.com/${linkType ? 'playlist' : 'user'}/${spotifyToken}`

        return (
            <OutboundLink
                className="spotify-link"
                eventLabel={`Spotify Link - ${venueName}`}
                style={{
                    color: linkColour,
                    backgroundColor: linkBgColour
                }}
                href={spotifyLink}
                target="_blank"
            >
                <FaSpotify />
                {linkText || 'Open the playlist in Spotify'}
            </OutboundLink>
        );
    }
}

SpotifyLink.defaultProps = {
    linkText: 'Open the playlist in Spotify',
    linkType: true,
    linkColour: '#000',
    linkBgColour: 'transparent',
    spotifyToken: '0bOpROzZCE7tF1O6BD4TE7',
    venueName: 'Open Ear'
};

SpotifyLink.propTypes = {
    linkText: PropTypes.string,
    linkType: PropTypes.bool,
    linkColour: PropTypes.string,
    linkBgColour: PropTypes.string,
    spotifyToken: PropTypes.string,
    venueName: PropTypes.string
};

export default SpotifyLink;
