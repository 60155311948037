import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ConnectHeader from '../components/ConnectHeader';
import ConnectHero from '../components/ConnectHero';
import ConnectSocial from '../components/ConnectSocial';
import EmailForm from '../components/EmailForm';
import Playlist from '../components/Playlist';
import PromoBlock from '../components/PromoBlock';
import OpenEarFooter from '../components/OpenEarFooter';
import OpenEarLoadingScreen from '../components/OpenEarLoadingScreen';
import SpotifyPlayer from '../components/SpotifyPlayer';
import SpotifyLink from '../components/SpotifyLink';
import SignUpForm from '../components/SignUpForm';
import SoundcloudEmbed from '../components/SoundcloudEmbed';

class IndexPage extends Component {
    getLayout() {
        const {
            data
        } = this.props;

        const {
            playlistToken,
            venueName
        } = data.contentfulVenue;

        const pageData = data.contentfulVenue.connectPage;

        if (!pageData.layout) {
            return null;
        }

        return (
            pageData.layout.map((layoutItem, index) => {
                switch (layoutItem.__typename) {
                case 'ContentfulHero':
                    return (
                        <ConnectHero
                            key={index}
                            venueName={venueName}
                            {...layoutItem}
                        />
                    );
                case 'ContentfulPromotionBlock':
                    return (
                        <PromoBlock
                            key={index}
                            venueName={venueName}
                            {...layoutItem}
                        />
                    );
                case 'ContentfulNowPlaying':
                    return (
                        <Playlist
                            key={index}
                            venueName={venueName}
                            playlistToken={playlistToken}
                            {...layoutItem}
                        />
                    );
                case 'ContentfulEmailForm':
                    return (
                        <EmailForm
                            key={index}
                            venueName={venueName}
                            form="inline-email-form"
                            {...layoutItem}
                        />
                    );
                case 'ContentfulSpotifyPlaylistEmbed':
                    return (
                        <SpotifyPlayer
                            key={index}
                            spotifyToken={layoutItem.spotifyEmbedSlug || pageData.spotifyToken}
                        />
                    );
                case 'ContentfulSpotifyLink':
                    return (
                        <SpotifyLink
                            key={index}
                            venueName={venueName}
                            {...layoutItem}
                        />
                    );
                case 'ContentfulSoundcloudEmbed':
                    return (
                        <SoundcloudEmbed
                            key={index}
                            {...layoutItem}
                        />
                    );
                case 'ContentfulSocial':
                    return (
                        <ConnectSocial
                            key={index}
                            venueName={venueName}
                            social={layoutItem}
                            bgColour={layoutItem.socialBgColour}
                            iconColour={layoutItem.socialIconColour}
                        />
                    );
                default: return null;
                }
            })
        );
    }

    useCustomFont() {
        const {
            data
        } = this.props;

        const pageData = data.contentfulVenue.connectPage;

        if (pageData.useCustomFont) {
            return `${pageData.useCustomFont},`;
        }

        return '';
    }

    render() {
        const {
            data
        } = this.props;

        const {
            slug
        } = data.contentfulVenue;

        const pageData = data.contentfulVenue.connectPage;

        return (
            <div style={{ fontFamily: `${this.useCustomFont()} Arial, sans-serif` || 'inherit' }}>
                <ConnectHeader
                    {...pageData}
                />
                {this.getLayout()}
                {slug === 'openear' && (
                    <SignUpForm
                        form="sign-up-form"
                        {...pageData}
                    />
                )}
                {pageData.showOpenEarBranding && (
                    <Fragment>
                        <OpenEarFooter />
                        <OpenEarLoadingScreen />
                    </Fragment>
                )}
            </div>
        );
    }
}

export const query = graphql`
    query($slug: String!) {
        contentfulVenue(slug: { eq: $slug }) {
            venueName
            playlistToken
            slug
            connectPage {
                showOpenEarBranding
                useCustomFont
                headerImage {
                    file {
                        url
                    }
                    title
                }
                layout {
                    ... on ContentfulHero {
                        heroText
                        heroTextColour
                        heroTextShadow
                        heroImage {
                            file {
                                url
                            }
                            title
                        }
                        heroCta {
                            ctaText
                            ctaColour
                            ctaBgColour
                            ctaFontWeight
                            ctaType
                            spotifyToken
                            emailForm {
                                formBgColour
                                formHeaderText
                                formHeaderTextColour
                                formText
                                formTextColour
                                formCtaText
                                formCtaTextColour
                                formCtaBgColour
                                policyLink
                                mailchimpEndpoint
                                spotifyLink {
                                    linkText
                                    spotifyToken
                                    linkType
                                    linkColour
                                    linkBgColour
                                }
                            }
                        }
                    }
                    ... on ContentfulPromotionBlock {
                        promoImage {
                            file {
                                url
                            }
                            title
                        }
                        promoText {
                            json
                        }
                        addPaddingToPromoTextImages
                        promoBlockBgImage {
                            file {
                                url
                            }
                            title
                        }
                        promoBlockBgColour
                        promoBlockTextColour
                        promoCta {
                            ctaText
                            ctaColour
                            ctaBgColour
                            ctaFontWeight
                            ctaType
                            spotifyToken
                            emailForm {
                                formBgColour
                                formHeaderText
                                formHeaderTextColour
                                formText
                                formTextColour
                                formCtaText
                                formCtaTextColour
                                formCtaBgColour
                                policyLink
                                mailchimpEndpoint
                                spotifyLink {
                                    linkText
                                    spotifyToken
                                    linkType
                                    linkColour
                                    linkBgColour
                                }
                            }
                        }
                    }
                    ... on ContentfulNowPlaying {
                        currentTrackBgColour
                        currentTrackTextColour
                        previousTrackBgColour
                        previousTrackTextColour
                    }
                    ... on ContentfulEmailForm {
                        formBgColour
                        formHeaderText
                        formHeaderTextColour
                        formText
                        formTextColour
                        formCtaText
                        formCtaTextColour
                        formCtaBgColour
                        policyLink
                        mailchimpEndpoint
                        spotifyLink {
                            linkText
                            spotifyToken
                            linkType
                            linkColour
                            linkBgColour
                        }
                    }
                    ... on ContentfulSpotifyLink {
                        linkText
                        spotifyToken
                        linkType
                        linkColour
                        linkBgColour
                    }
                    ... on ContentfulSpotifyPlaylistEmbed {
                        spotifyEmbedSlug
                    }
                    ... on ContentfulSoundcloudEmbed {
                        embedCode {
                            embedCode
                        }
                    }
                    ... on ContentfulSocial {
                        socialText
                        socialBgColour
                        socialIconColour
                        twitterLink
                        facebookLink
                        instagramLink
                        pinterestLink
                        linkedInLink
                        youTubeLink
                    }
                }
            }
        }
    }
`;

IndexPage.defaultProps = {
    data: {}
};

IndexPage.propTypes = {
    data: PropTypes.object
};

export default IndexPage;
